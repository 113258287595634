<template>
  <CommonApp>
    <template #footer>
      <AppFooter />
    </template>
  </CommonApp>
</template>

<script>
import CommonApp from '@/common-app.vue';
import AppFooter from '@/components/app/AppFooter.vue';

export default {
  name: 'SkodaApp',
  metaInfo: {
    title: process.env.siteName,
  },
  components: {
    CommonApp,
    AppFooter,
  },
};
</script>
