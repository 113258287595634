import bootstrap from '@/common-main';
import App from './skoda-app.vue';
import store from './skoda-store';
import router from './skoda-router';

const progressbarOptions = {
  color: '#0e3a2f',
};

bootstrap({
  App, store, router, progressbarOptions,
});
